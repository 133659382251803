<template>
  <div class="d-none" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="confirmModal"
      ref="confirmModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
      @hidden="handleCloseModal"
    >
      <div class="text-center">
        <font-awesome-icon
          v-if="showIcon"
          class="icon-alert"
          :icon="['fas', 'exclamation-circle']"
        />
        <p class="text-text" v-if="text">{{ text }}</p>
        <div class="tw-mt-6 tw-text-left">
          <div class="text-area-container tw-w-full">
            <InputTextArea
              v-model="data"
              class="tw-w-full"
              id="textarea"
              :textFloat="label"
              :placeholder="placeholder"
              type="text"
              rows="3"
              max-rows="6"
              name="textarea"
              :isRequired="isRequiredNote"
              :v="$v.data"
              :isValidate="$v.data.$error"
            />
          </div>
        </div>
      </div>
      <div class="text-center mt-3">
        <b-button
          variant="outline-secondary"
          pill
          class="mr-2 px-4"
          @click="handleCloseModal"
        >
          {{ button.false.msg }}</b-button
        >
        <b-button
          variant="primary"
          :id="id"
          pill
          class="px-4"
          @click="handleOkModal"
        >
          {{ button.true.msg }}</b-button
        >
      </div>
      <div class="text-center mt-2"></div>
    </b-modal>
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  components: { InputTextArea },
  props: {
    text: {
      required: false,
      type: String,
    },
    showIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
    id: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Enter something...",
    },
    label: {
      required: false,
      type: String,
      default: "Data",
    },
    button: {
      required: false,
      type: Object,
      default: () => {
        return {
          true: {
            class: ["btn-success"],
            msg: "OK",
            id: ["logout"],
          },
          false: {
            class: ["btn-danger"],
            msg: "Cancel",
          },
        };
      },
    },
    isRequiredNote: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modalShow: false,
      data: "",
    };
  },
  validations() {
    return {
      data: {
        required: requiredIf(() => this.isRequiredNote === true),
      },
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("handler", true, this.data);
      this.data = "";
      this.modalShow = false;
    },
    handleCloseModal() {
      this.$emit("handler", false);
      this.data = "";
      this.modalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmModal {
  width: auto;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-header {
  font-size: 18px;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.text-second-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1rem;
  word-wrap: break-word !important;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.icon-alert {
  font-size: 50px;
  margin: 10px 0;
  color: var(--primary-color);
}

.text-area-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  :deep(textarea) {
    resize: none;
    height: 140px;
  }
}
</style>
